/* eslint-disable */
<template>
  <div>
    <b-modal
      v-model="showModal"
      ok-only
      ok-title="Accept"
      centered
      size="xl"
      :hide-footer="true"
      :title="product.name || product.title"
      lazy
      static
    >
      <b-modal
        v-model="pinterestProduct"
        ok-only
        ok-title="Accept"
        centered
        size="xl"
        :hide-footer="true"
        title="Publication pinterest"
        lazy
        static
      >
        <Pinterest
          v-if="pinterestProduct"
          :product="product"
          @close="pinterestProduct = false"
        />
      </b-modal>
      <b-row class="align-items-center mb-1">
        <b-col md="2">
          <a class="mb-2" :href="`https://kipli.com/fr/product/${product.slug}`" target="_blank">
            <b-button
              variant="primary"
              class="btn-cart"
            >
              <span>Page produit</span>
            </b-button>
          </a>
        </b-col>
        <b-col md="3">
          <b-button
            variant="primary"
            class="btn-cart"
            :disabled="disabledImport"
            @click="importWoo(pdct)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            <span>Importer depuis WooCommerce</span>
          </b-button>
        </b-col>
        <b-col
          cols="3"
        >
          <div class="d-flex align-items-center">
            <b-form-file
              v-model="file"
              placeholder="Importer contenu"
              drop-placeholder="Drop fichier ici..."
              accept=".csv"
            />
            <b-button
              class="ml-2"
              variant="primary"
              :disabled="!file"
              @click="upload"
            >
              Importer
            </b-button>
          </div>
        </b-col>
        <b-col md="2">
          <b-button
            variant="primary"
            class="btn-cart"
            @click="pinterestProduct = true"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            <span>Pinterest</span>
          </b-button>
        </b-col>
        <b-col md="2">
          <Media />
        </b-col>
      </b-row>
      <div v-if="product.categoryInformations">
        <!-- <v-jsoneditor
          v-model="categoryInformations"
          :options="options"
          height="600px"
          @error="errorJson"
        /> -->
        <quill-editor-snow style="display: none;" />
        <app-collapse>
          <app-collapse-item title="Premier bloc">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Informations:</p>
                  <quill-editor
                    v-if="categoryInformations"
                    v-model="categoryInformations.lg.tabs[0].value"
                    class="mb-2"
                    :options="snowOption"
                  />
                  <!-- <p>Caractéristiques:</p>
                  <quill-editor
                    v-if="categoryInformations"
                    v-model="categoryInformations.lg.tabs[1].value"
                    :options="snowOption"
                  />
                  <p>Livraison:</p>
                  <quill-editor
                    v-if="categoryInformations"
                    v-model="categoryInformations.lg.tabs[2].value"
                    :options="snowOption"
                  /> -->
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary "
              >
                <b-img
                  fluid
                  :src="require(`@/assets/images/editor/tabs-v2.png`)"
                />
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Premier bloc V2">
            <b-row
              class="my-2"
            >
              <div>
                <b-row
                  v-for="(value, keyR) of categoryInformations.lg.descriptions"
                  :key="keyR"
                >
                  <b-col
                    cols="3"
                    class="primary my-4"
                  >
                    <p>Titre:</p>
                    <b-form-input
                      v-if="value"
                      v-model="value.title"
                      class="mr-4"
                      style="width: 100%"
                    />
                  </b-col>
                  <b-col
                    cols="6"
                    class="primary my-4"
                  >
                    <p>Description:</p>
                    <quill-editor
                      v-if="value"
                      v-model="value.description"
                      :options="snowOption"
                      style="width: 100%"
                    />
                  </b-col>
                  <b-col
                    cols="1"
                    class="primary my-4"
                  >
                    <b-button
                      variant="primary"
                      class="btn-icon"
                      @click="categoryInformations.lg.descriptions.splice(keyR, 1);"
                    >
                      <feather-icon icon="Trash2Icon" />
                    </b-button>
                  </b-col>
                </b-row>
                <b-col
                  span="12"
                  class="primary d-flex justify-content-end"
                >
                  <b-button
                    variant="primary"
                    @click="() => categoryInformations.lg.descriptions.push({title: '', description: ''})"
                  >
                    <span class="text-nowrap">Ajouter un bloc</span>
                  </b-button>
                </b-col>
              </div>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Image / Vidéo">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Nom de la vidéo:</p>
                  <b-form-input
                    v-model="categoryInformations.lg.videoGrid"
                    style="width: 100%"
                  />
                </div>
              </b-col>
            </b-row>
            <b-row
              class="my-2"
            >
              <b-col
                cols="3"
              >
                <div>
                  <p>Version:</p>
                  <v-select
                    v-model="categoryInformations.lg.imageVersion"
                    placeholder="Type"
                    :options="['kipli', 'cloudinary']"
                    :clearable="false"
                    class="invoice-filter-select mt-1"
                  />
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Image Quantity (Default: 5):</p>
                  <b-form-input
                    v-model="categoryInformations.lg.imageQuantity"
                    type="number"
                    style="width: 100%"
                  />
                </div>
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Titre Petit Cross-sell">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Titre:</p>
                  <b-form-input
                    v-model="categoryInformations.lg.titleLittleCrossSell"
                    style="width: 100%"
                  />
                </div>
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Titre Bloc Cross-sell">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Titre:</p>
                  <b-form-input
                    v-model="categoryInformations.lg.titleCrossSell"
                    style="width: 100%"
                  />
                </div>
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Vidéo schéma">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Url de la vidéo:</p>
                  <b-form-input
                    v-model="categoryInformations.lg.videoSchema"
                    style="width: 100%"
                  />
                </div>
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Fabrication">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>KEY:</p>
                  <b-form-input
                    v-model="categoryInformations.lg.fabricationKey"
                    style="width: 100%"
                  />
                </div>
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Dessin">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>KEY:</p>
                  <b-form-input
                    v-model="categoryInformations.lg.dessinKey"
                    style="width: 100%"
                  />
                </div>
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Schéma mesure">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Url du schéma:</p>
                  <b-form-input
                    v-model="categoryInformations.lg.schemaMesure"
                    style="width: 100%"
                  />
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary "
              >
                <b-img
                  fluid
                  :src="require(`@/assets/images/editor/schema-v2.png`)"
                />
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Réassurance">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <!-- <p>Titre:</p>
                  <b-form-input
                    v-if="categoryInformations"
                    v-model="categoryInformations.lg.reinsurance.title"
                    class="mb-2"
                  />
                  <p>Sous-titre:</p>
                  <quill-editor
                    v-if="categoryInformations"
                    v-model="categoryInformations.lg.reinsurance.subtitle"
                    :options="snowOption"
                    class="mb-2"
                  /> -->
                  <p>Icones:</p>
                  <b-row
                    v-for="(value, keyR) of categoryInformations.lg.reinsurance.values"
                    :key="keyR"
                  >
                    <b-col
                      cols="3"
                      class="primary my-4"
                    >
                      <p>Titre:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.title"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="2"
                      class="primary my-4"
                    >
                      <p>Icone:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.icon"
                        class="mr-4"
                        style="width: 100%"
                      />
                      <!--<b-img
                        fluid
                        :src="require(`@/assets/images/front/${value.icon}.svg`)"
                      />!-->
                    </b-col>
                    <b-col
                      cols="6"
                      class="primary my-4"
                    >
                      <p>Texte:</p>
                      <quill-editor
                        v-if="value"
                        v-model="value.description"
                        :options="snowOption"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="1"
                      class="primary my-4"
                    >
                      <b-button
                        variant="primary"
                        class="btn-icon"
                        @click="categoryInformations.lg.reinsurance.values.splice(keyR, 1);"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-col
                    span="12"
                    class="primary d-flex justify-content-end"
                  >
                    <b-button
                      variant="primary"
                      @click="() => categoryInformations.lg.reinsurance.values.push({title: '', icon: '', description: ''})"
                    >
                      <span class="text-nowrap">Ajouter une réassurance</span>
                    </b-button>
                  </b-col>
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary"
              >
                <b-img
                  fluid
                  :src="require(`@/assets/images/editor/pictos-v2.png`)"
                />
              </b-col>
            </b-row>
          </app-collapse-item>

          <app-collapse-item title="Matériaux">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Blocs:</p>
                  <b-row
                    v-for="(value, keyMaterial) of categoryInformations.lg.materials.values"
                    :key="keyMaterial"
                  >
                    <b-col
                      cols="3"
                      class="primary my-4"
                    >
                      <p>Titre:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.title"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="8"
                      class="primary my-4"
                    >
                      <p>Texte:</p>
                      <quill-editor
                        v-model="value.content"
                        :options="snowOption"
                      />
                    </b-col>
                    <b-col
                      cols="4"
                      class="primary my-4"
                    >
                      <p>Image:</p>
                      <b-form-input
                        v-if="value && value.image"
                        v-model="value.image.src"
                        class="mr-4"
                        style="width: 100%"
                      />
                      <img v-if="value && value.image && value.image.src" :src="value.image.src" style="width: 70px" />
                    </b-col>
                    <b-col
                      cols="6"
                      class="primary my-4"
                    >
                      <p>Alt:</p>
                      <b-form-input
                        v-if="value && value.image"
                        v-model="value.image.alt"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="1"
                      class="primary my-4"
                    >
                      <b-button
                        variant="primary"
                        class="btn-icon"
                        @click="categoryInformations.lg.materials.values.splice(keyMaterial, 1);"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-col
                    span="12"
                    class="primary d-flex justify-content-end"
                  >
                    <b-button
                      variant="primary"
                      @click="categoryInformations.lg.materials.values.push({title: '', content: '', image: { src: '', alt: '' }})"
                    >
                      <span class="text-nowrap">Ajouter un bloc</span>
                    </b-button>
                  </b-col>
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary"
              >
                <b-img
                  fluid
                  :src="require(`@/assets/images/editor/labels.png`)"
                />
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Histoire">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div v-if="categoryInformations">
                  <p>Titre:</p>
                  <b-form-input
                    v-model="categoryInformations.lg.history.title"
                    class="mb-2"
                  />
                  <p>Contenu:</p>
                  <quill-editor
                    v-model="categoryInformations.lg.history.description"
                    :options="snowOption"
                  />
                  <!-- <p>Lien (url):</p>
                  <b-form-input
                    v-model="categoryInformations.lg.history.action.link"
                    class="mb-2"
                  />
                  <p>Lien (texte):</p>
                  <b-form-input
                    v-model="categoryInformations.lg.history.action.text"
                    class="mb-2"
                  /> -->
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <b-img
                  fluid
                  :src="require(`@/assets/images/editor/history-v2.png`)"
                />
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Certifications">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Titre:</p>
                  <b-form-input
                    v-if="categoryInformations"
                    v-model="categoryInformations.lg.certifications.title"
                    class="mr-4"
                    style="width: 100%"
                  />
                  <p>Image:</p>
                  <b-form-input
                    v-if="categoryInformations"
                    v-model="categoryInformations.lg.certifications.image"
                    class="mr-4"
                    style="width: 100%"
                  />
                  <img v-if="categoryInformations.lg.certifications.image" :src="categoryInformations.lg.certifications.image" style="width: 70px" />
                  <p>Icones:</p>
                  <b-row
                    v-for="(value, keyC) of categoryInformations.lg.certifications.values"
                    :key="keyC"
                  >
                    <b-col
                      cols="3"
                      class="primary my-4"
                    >
                      <p>Titre:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.title"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="2"
                      class="primary my-4"
                    >
                      <p>Icone:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.icon"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="6"
                      class="primary my-4"
                    >
                      <p>Texte:</p>
                      <quill-editor
                        v-if="value"
                        v-model="value.text"
                        :options="snowOption"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="1"
                      class="primary my-4"
                    >
                      <b-button
                        variant="primary"
                        class="btn-icon"
                        @click="categoryInformations.lg.certifications.values.splice(keyC, 1);"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-col
                    span="12"
                    class="primary d-flex justify-content-end"
                  >
                    <b-button
                      variant="primary"
                      @click="() => categoryInformations.lg.certifications.values.push({title: '', icon: '', text: ''})"
                    >
                      <span class="text-nowrap">Ajouter une certification</span>
                    </b-button>
                  </b-col>
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary"
              >
                <b-img
                  fluid
                  :src="require(`@/assets/images/editor/certifications-v2.png`)"
                />
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Pictos">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Icones:</p>
                  <b-row
                    v-for="(value, key) of categoryInformations.lg.pictos"
                    :key="key"
                  >
                    <b-col
                      cols="6"
                      class="primary my-4"
                    >
                      <p>Titre:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.title"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="5"
                      class="primary my-4"
                    >
                      <p>Icone:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.icon"
                        class="mr-4"
                        style="width: 100%"
                      />
                      <!--<b-img
                        fluid
                        :src="require(`@/assets/images/front/${value.icon}.svg`)"
                      />!-->
                    </b-col>
                    <b-col
                      cols="1"
                      class="primary my-4"
                    >
                      <b-button
                        variant="primary"
                        class="btn-icon"
                        @click="categoryInformations.lg.pictos.splice(key, 1);"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-col
                    span="12"
                    class="primary d-flex justify-content-end"
                  >
                    <b-button
                      variant="primary"
                      @click="() => categoryInformations.lg.pictos.push({title: '', text: ''})"
                    >
                      <span class="text-nowrap">Ajouter un picto</span>
                    </b-button>
                  </b-col>
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary"
              >
                <b-img
                  fluid
                  :src="require(`@/assets/images/editor/reinsurance-v2.png`)"
                />
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Matière">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Titre principal:</p>
                  <b-form-input
                    v-model="categoryInformations.lg.matter.title"
                    class="mr-4"
                    style="width: 100%"
                  />
                  <p>Bloc 1:</p>
                  <b-row v-if="categoryInformations.lg.matter">
                    <b-col
                      cols="3"
                      class="primary my-4"
                    >
                      <p>Titre:</p>
                      <b-form-input
                        v-model="categoryInformations.lg.matter.bloc1.title"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="8"
                      class="primary my-4"
                    >
                      <p>Texte:</p>
                      <quill-editor
                        v-model="categoryInformations.lg.matter.bloc1.description"
                        :options="snowOption"
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      class="my-4"
                    >
                      <p>Image:</p>
                      <b-form-input
                        v-model="categoryInformations.lg.matter.bloc1.image"
                        class="mr-4"
                        style="width: 100%"
                      />
                      <img v-if="categoryInformations.lg.matter.bloc1.image" :src="categoryInformations.lg.matter.bloc1.image" style="width: 70px" />
                    </b-col>
                    <b-col
                      cols="6"
                      class="my-4"
                    >
                      <p>Petite image 1:</p>
                      <b-form-input
                        v-model="categoryInformations.lg.matter.bloc1.littleImage1"
                        class="mr-4"
                        style="width: 100%"
                      />
                      <img v-if="categoryInformations.lg.matter.bloc1.littleImage1" :src="categoryInformations.lg.matter.bloc1.littleImage1" style="width: 70px" />
                    </b-col>
                    <b-col
                      cols="6"
                      class="my-4"
                    >
                      <p>Petite image 2:</p>
                      <b-form-input
                        v-model="categoryInformations.lg.matter.bloc1.littleImage2"
                        class="mr-4"
                        style="width: 100%"
                      />
                      <img v-if="categoryInformations.lg.matter.bloc1.littleImage2" :src="categoryInformations.lg.matter.bloc1.littleImage2" style="width: 70px" />
                    </b-col>
                    <b-col
                      cols="12"
                      class="my-4"
                    >
                      <p>Alt:</p>
                      <b-form-input
                        v-model="categoryInformations.lg.matter.bloc1.alt"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                  </b-row>
                  <p>Description déroulante bloc 1</p>
                  <b-row
                    v-for="(value, keyMatter) of categoryInformations.lg.matter.bloc1.values"
                    :key="keyMatter"
                  >
                    <b-col
                      cols="3"
                      class="primary my-4"
                    >
                      <p>Titre:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.title"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="8"
                      class="primary my-4"
                    >
                      <p>Texte:</p>
                      <quill-editor
                        v-model="value.content.content"
                        :options="snowOption"
                      />
                    </b-col>
                    <b-col
                      cols="1"
                      class="primary my-4"
                    >
                      <b-button
                        variant="primary"
                        class="btn-icon"
                        @click="categoryInformations.lg.matter.bloc1.values.splice(keyMatter, 1);"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-col
                    span="12"
                    class="primary d-flex justify-content-end"
                  >
                    <b-button
                      variant="primary"
                      @click="categoryInformations.lg.matter.bloc1.values.push({title: '', content: { content: '' }})"
                    >
                      <span class="text-nowrap">Ajouter un bloc</span>
                    </b-button>
                  </b-col>
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary my-4"
              >
                <b-col
                  cols="3"
                  class="primary my-4"
                >
                  <p>Titre du lien:</p>
                  <b-form-input
                    v-model="categoryInformations.lg.matter.textLink"
                    class="mr-4"
                    style="width: 100%"
                  />
                </b-col>
                <b-col
                  cols="3"
                  class="primary my-4"
                >
                  <p>Lien (ex: /fr/en-savoir-plus):</p>
                  <b-form-input
                    v-model="categoryInformations.lg.matter.link"
                    class="mr-4"
                    style="width: 100%"
                  />
                </b-col>
                <b-col
                  cols="12"
                  class="primary"
                >
                  <b-img
                    fluid
                    :src="require(`@/assets/images/editor/matiere.png`)"
                  />
                </b-col>
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Press" v-if="categoryInformations.lg.publications">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Titre:</p>
                  <b-form-input
                    v-model="categoryInformations.lg.publications.title"
                    class="mb-2"
                  />
                  <b-row
                    v-for="(value, keyPub) of categoryInformations.lg.publications.values"
                    :key="keyPub"
                  >
                    <b-col
                      cols="3"
                      class="primary my-4"
                    >
                      <p>Titre:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.title"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="4"
                      class="primary my-4"
                    >
                      <p>Contenu:</p>
                      <quill-editor
                        v-if="value"
                        v-model="value.description"
                        class="mb-2"
                        :options="snowOption"
                      />
                    </b-col>
                    <b-col
                      cols="4"
                      class="primary my-4"
                    >
                      <p>Url image:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.src"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="1"
                      class="primary my-4"
                    >
                      <b-button
                        variant="primary"
                        class="btn-icon"
                        @click="categoryInformations.lg.publications.values.splice(keyPub, 1);"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-col
                    span="12"
                    class="primary d-flex justify-content-end"
                  >
                    <b-button
                      variant="primary"
                      @click="() => categoryInformations.lg.publications.values.push({title: '', src: '', description: ''})"
                    >
                      <span class="text-nowrap">Ajouter une publication</span>
                    </b-button>
                  </b-col>
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary"
              >
                <b-img
                  fluid
                  :src="require(`@/assets/images/editor/faq.png`)"
                />
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Caractéristiques">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Titre:</p>
                  <b-form-input
                    v-if="categoryInformations"
                    v-model="categoryInformations.lg.details.title"
                    class="mb-2"
                    :options="snowOption"
                  />
                  <b-row
                    v-for="(value, keyV) of categoryInformations.lg.details.values"
                    :key="keyV"
                  >
                    <b-col
                      cols="3"
                      class="primary my-4"
                    >
                      <p>Titre:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.title"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="8"
                      class="primary my-4"
                    >
                      <p>Contenu:</p>
                      <quill-editor
                        v-if="value.content"
                        v-model="value.content.content"
                        class="mb-2"
                        :options="snowOption"
                      />
                    </b-col>
                    <b-col
                      cols="1"
                      class="primary my-4"
                    >
                      <b-button
                        variant="primary"
                        class="btn-icon"
                        @click="categoryInformations.lg.details.values.splice(keyV, 1);"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-col
                    span="12"
                    class="primary d-flex justify-content-end"
                  >
                    <b-button
                      variant="primary"
                      @click="() => categoryInformations.lg.details.values.push({title: '', content: { content: '' }})"
                    >
                      <span class="text-nowrap">Ajouter un détail</span>
                    </b-button>
                  </b-col>
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary"
              >
                <b-img
                  fluid
                  :src="require(`@/assets/images/editor/caracteristiques-v2.png`)"
                />
              </b-col>
            </b-row>
          </app-collapse-item>
          <!-- <app-collapse-item title="New FAQ">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Titre:</p>
                  <b-form-input
                    v-if="categoryInformations"
                    v-model="categoryInformations.lg.faqV2.title"
                    class="mb-2"
                  />
                  <div v-if="categoryInformations.lg.faqV2.values">
                    <b-row
                      v-for="(value, keyFaq) of categoryInformations.lg.faqV2.values"
                      id="section"
                      :key="`${keyFaq}-section`"
                    >
                      <b-col
                        cols="3"
                        class="primary my-4"
                      >
                        <p>Titre:</p>
                        <b-form-input
                          v-if="value"
                          v-model="value.title"
                          class="mr-4"
                          style="width: 100%"
                        />
                      </b-col>
                      <b-col
                        cols="1"
                        class="primary my-4"
                      >
                        <b-button
                          variant="primary"
                          class="btn-icon"
                          @click="deleteElementFaqV2(keyFaq)"
                        >
                          <feather-icon icon="Trash2Icon" />
                        </b-button>
                      </b-col>
                      {{ value }}
                      <b-row
                        v-for="(subSection, keySection) of value.sections"
                        :key="`${keySection}-section`"
                      >
                        <b-col
                          cols="3"
                          class="primary my-4"
                        >
                          <p>Sous-titre:</p>
                          <b-form-input
                            v-if="subSection"
                            v-model="subSection.title"
                            class="mr-4"
                            style="width: 100%"
                          />
                        </b-col>
                        <b-col
                          cols="8"
                          class="primary my-4"
                        >
                          <p>Contenu:</p>
                          <quill-editor
                            v-if="subSection.content"
                            v-model="subSection.content"
                            class="mb-2"
                            :options="snowOption"
                          />
                        </b-col>
                        <b-col
                          cols="1"
                          class="primary my-4"
                        >
                          <b-button
                            variant="primary"
                            class="btn-icon"
                            @click="value.sections.splice(keySection, 1);"
                          >
                            <feather-icon icon="Trash2Icon" />
                          </b-button>
                        </b-col>
                        <b-col
                          span="2"
                          class="primary d-flex justify-content-end"
                        >
                          <b-button
                            variant="primary"
                            @click="value.sections.push({title: '', content: '' })"
                          >
                            <span class="text-nowrap">Ajouter une sous section</span>
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-row>
                  </div>
                  <b-col
                    span="12"
                    class="primary d-flex justify-content-end"
                  >
                    <b-button
                      variant="primary"
                      @click="() => categoryInformations.lg.faqV2.values.push({title: '', sections: [{title: '', content: ''}]})"
                    >
                      <span class="text-nowrap">Ajouter une ligne FAQ</span>
                    </b-button>
                  </b-col>
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary"
              >
                <b-img
                  fluid
                  :src="require(`@/assets/images/editor/caracteristiques-v2.png`)"
                />
              </b-col>
            </b-row>
          </app-collapse-item> -->
          <app-collapse-item title="FAQ">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Titre:</p>
                  <b-form-input
                    v-if="categoryInformations"
                    v-model="categoryInformations.lg.faq.title"
                    class="mb-2"
                    :options="snowOption"
                  />
                  <b-row
                    v-for="(value, keyF) of categoryInformations.lg.faq.values"
                    :key="keyF"
                  >
                    <b-col
                      cols="3"
                      class="primary my-4"
                    >
                      <p>Titre:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.title"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="8"
                      class="primary my-4"
                    >
                      <p>Contenu:</p>
                      <quill-editor
                        v-if="value.content"
                        v-model="value.content.content"
                        class="mb-2"
                        :options="snowOption"
                      />
                    </b-col>
                    <b-col
                      cols="1"
                      class="primary my-4"
                    >
                      <b-button
                        variant="primary"
                        class="btn-icon"
                        @click="categoryInformations.lg.faq.values.splice(keyF, 1);"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-col
                    span="12"
                    class="primary d-flex justify-content-end"
                  >
                    <b-button
                      variant="primary"
                      @click="() => categoryInformations.lg.faq.values.push({title: '', content: { content: '' }})"
                    >
                      <span class="text-nowrap">Ajouter une ligne FAQ</span>
                    </b-button>
                  </b-col>
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary"
              >
                <b-img
                  fluid
                  :src="require(`@/assets/images/editor/caracteristiques-v2.png`)"
                />
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Savoir-faire">
            <b-row
              class="my-2"
            >
              <b-col
                cols="3"
                class="primary my-4"
              >
                <p>Titre:</p>
                <b-form-input
                  v-if="categoryInformations.lg.knows"
                  v-model="categoryInformations.lg.knows.title"
                  class="mr-4"
                  style="width: 100%"
                />
              </b-col>
              <b-col
                cols="8"
                class="primary my-4"
              >
                <p>Texte:</p>
                <quill-editor
                  v-model="categoryInformations.lg.knows.content"
                  :options="snowOption"
                />
              </b-col>
              <b-col
                cols="4"
                class="primary my-4"
              >
                <p>Image:</p>
                <b-form-input
                  v-if="categoryInformations.lg.knows"
                  v-model="categoryInformations.lg.knows.imageSrc"
                  class="mr-4"
                  style="width: 100%"
                />
                <img v-if="categoryInformations.lg.knows && categoryInformations.lg.knows.imageSrc" :src="categoryInformations.lg.knows.imageSrc" style="width: 70px" />
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Engagement">
            <b-row
              class="my-2"
            >
              <b-col
                cols="3"
                class="primary my-4"
              >
                <p>Titre:</p>
                <b-form-input
                  v-if="categoryInformations.lg.commitment"
                  v-model="categoryInformations.lg.commitment.title"
                  class="mr-4"
                  style="width: 100%"
                />
              </b-col>
              <b-col
                cols="8"
                class="primary my-4"
              >
                <p>Texte:</p>
                <quill-editor
                  v-model="categoryInformations.lg.commitment.content"
                  :options="snowOption"
                />
              </b-col>
              <b-col
                cols="4"
                class="primary my-4"
              >
                <p>Image:</p>
                <b-form-input
                  v-if="categoryInformations.lg.commitment"
                  v-model="categoryInformations.lg.commitment.imageSrc"
                  class="mr-4"
                  style="width: 100%"
                />
                <img v-if="categoryInformations.lg.commitment && categoryInformations.lg.commitment.imageSrc" :src="categoryInformations.lg.commitment.imageSrc" style="width: 70px" />
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="SEO" v-if="categoryInformations.lg.seo">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Titre:</p>
                  <b-form-input
                    v-if="categoryInformations"
                    v-model="categoryInformations.lg.seo.title"
                    class="mr-4"
                    style="width: 100%"
                  />
                  <p>Image:</p>
                  <b-form-input
                    v-if="categoryInformations"
                    v-model="categoryInformations.lg.seo.image"
                    class="mr-4 mb-2"
                    style="width: 100%"
                  />
                  <p>Alt:</p>
                  <b-form-input
                    v-if="categoryInformations"
                    v-model="categoryInformations.lg.seo.alt"
                    class="mr-4 mb-2"
                    style="width: 100%"
                  />
                  <p>Blocs:</p>
                  <b-row
                    v-for="(value, keyBloc) of categoryInformations.lg.seo.blocs"
                    :key="keyBloc"
                  >
                    <b-col
                      cols="3"
                      class="primary my-4"
                    >
                      <p>Titre:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.title"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="6"
                      class="primary my-4"
                    >
                      <p>Texte:</p>
                      <quill-editor
                        v-if="value"
                        v-model="value.text"
                        class="mb-2"
                        :options="snowOption"
                      />
                    </b-col>
                    <b-col
                      cols="1"
                      class="primary my-4"
                    >
                      <b-button
                        variant="primary"
                        class="btn-icon"
                        @click="categoryInformations.lg.seo.blocs.splice(keyBloc, 1);"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-col
                    span="12"
                    class="primary d-flex justify-content-end"
                  >
                    <b-button
                      variant="primary"
                      @click="addBloc"
                    >
                      <span class="text-nowrap">Ajouter un bloc</span>
                    </b-button>
                  </b-col>
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary"
              >
                <b-img
                  fluid
                  :src="require(`@/assets/images/editor/labels.png`)"
                />
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Livraison">
            <b-row
              class="my-2"
            >
              <b-col
                cols="12"
                class="primary d-flex"
              >
                <div>
                  <p>Information:</p>
                  <b-form-input
                    v-if="categoryInformations"
                    v-model="categoryInformations.lg.shipping"
                    class="mr-4"
                    style="width: 100%"
                  />
                </div>
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Avis">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Titre:</p>
                  <b-form-input
                    v-model="categoryInformations.lg.reviewsTitle"
                    class="mb-2"
                  />
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary "
              >
                <b-img
                  fluid
                  :src="require(`@/assets/images/editor/services.png`)"
                />
              </b-col>
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Vidéo:</p>
                  <b-form-input
                    v-model="categoryInformations.lg.reviewsVideo"
                    class="mb-2"
                  />
                </div>
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Catégorie - Pictos">
            <b-row
              class="my-2"
            >
              <b-col
                cols="12"
                class="primary d-flex"
              >
                <div>
                  <p>Blocs:</p>
                  <b-row
                    v-for="(picto, keyPicto) of categoryInformations.lg.pictosCategory"
                    :key="keyPicto"
                  >
                    <b-col
                      cols="6"
                      class="primary my-4"
                    >
                      <b-form-input
                        v-model="categoryInformations.lg.pictosCategory[keyPicto]"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="1"
                      class="primary my-4"
                    >
                      <b-button
                        variant="primary"
                        class="btn-icon"
                        @click="categoryInformations.lg.pictosCategory.splice(keyPicto, 1);"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-col
                    span="12"
                    class="primary d-flex justify-content-end"
                  >
                    <b-button
                      variant="primary"
                      @click="categoryInformations.lg.pictosCategory.push('')"
                    >
                      <span class="text-nowrap">Ajouter un bloc</span>
                    </b-button>
                  </b-col>
                </div>
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Catégorie - Points forts">
            <b-row
              class="my-2"
            >
              <b-col
                cols="12"
                class="primary d-flex"
              >
                <div>
                  <p>Blocs:</p>
                  <b-row
                    v-for="(information, keyInformation) of categoryInformations.lg.informationsPointCategory"
                    :key="keyInformation"
                  >
                    <b-col
                      cols="10"
                      class="primary my-4"
                    >
                      <b-form-input
                        v-model="categoryInformations.lg.informationsPointCategory[keyInformation]"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="1"
                      class="primary my-4"
                    >
                      <b-button
                        variant="primary"
                        class="btn-icon"
                        @click="categoryInformations.lg.informationsPointCategory.splice(keyInformation, 1);"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-col
                    span="12"
                    class="primary d-flex justify-content-end"
                  >
                    <b-button
                      variant="primary"
                      @click="categoryInformations.lg.informationsPointCategory.push('')"
                    >
                      <span class="text-nowrap">Ajouter un bloc</span>
                    </b-button>
                  </b-col>
                </div>
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Catégorie - Position">
            <b-row
              class="my-2"
            >
              <b-col
                cols="12"
                class="primary d-flex"
              >
                <p>Position:</p>
                <b-form-input
                  v-model="categoryInformations.lg.categoryPlace"
                  type="number"
                  class="mr-4"
                  style="width: 100%"
                />
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Catégorie - Title & Subtitle">
            <b-row
              class="my-2"
            >
              <b-col
                cols="12"
                class="primary d-flex"
              >
                <p>Titre:</p>
                <b-form-input
                  v-model="categoryInformations.lg.title"
                  type="text"
                  class="mr-4"
                  style="width: 100%"
                />
              </b-col>
            </b-row>
            <b-row
              class="my-2"
            >
              <b-col
                cols="12"
                class="primary d-flex"
              >
                <p>Sous-titre:</p>
                <b-form-input
                  v-model="categoryInformations.lg.subTitle"
                  type="text"
                  class="mr-4"
                  style="width: 100%"
                />
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Catégorie - Default Color">
            <b-row
              class="my-2"
            >
              <b-col
                cols="12"
                class="primary d-flex"
              >
                <p>Color name:</p>
                <b-form-input
                  v-model="categoryInformations.lg.colorCategory"
                  type="text"
                  class="mr-4"
                  style="width: 100%"
                />
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Blog" v-if="categoryInformations.lg.snackContent">
            <b-row
              class="my-2"
            >
              <b-col
                cols="3"
                class="primary d-flex"
              >
                <div>
                  <p>Description:</p>
                  <b-form-input
                    v-model="categoryInformations.lg.snackContent.description"
                    class="mb-2"
                  />
                </div>
              </b-col>
              <b-col
                cols="3"
                class="primary d-flex"
              >
                <div>
                  <p>Réassurance:</p>
                  <b-form-input
                    v-model="categoryInformations.lg.snackContent.reinsurance"
                    class="mb-2"
                  />
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary "
              >
                <b-img
                  fluid
                  :src="require(`@/assets/images/editor/blog.png`)"
                />
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Feed - Shopping">
            <b-row
              class="my-2"
            >
              <b-col
                cols="12"
                class="primary d-flex"
              >
                <p>Matière:</p>
                <b-form-input
                  v-model="categoryInformations.lg.materialShopping"
                  type="text"
                  class="mr-4"
                  style="width: 100%"
                />
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Feed - Category">
            <b-row
              class="my-2"
            >
              <b-col
                cols="12"
                class="primary d-flex"
              >
                <p>Catégorie:</p>
                <v-select v-model="categoryInformations.lg.categoryShopping" :options="['PILLOW','DUVET','BED_TEXTILE','MATTRESS','BEDS','KIDS','TOPPER','HOME_FURNITURE','HOME_TEXTILE','SOFA','DECORATION','BEDROOM_FURNITURE','LIGHTNING']" placehoder="Categorie" />
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Easter EGGS">
            <b-row
              class="my-2"
            >
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Product:</p>
                  <b-form-input
                    v-model="categoryInformations.lg.productEggs"
                    class="mb-2"
                  />
                </div>
              </b-col>
              <b-col
                cols="6"
                class="primary d-flex"
              >
                <div>
                  <p>Code promo:</p>
                  <b-form-input
                    v-model="categoryInformations.lg.codeEggs"
                    class="mb-2"
                  />
                </div>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
        <b-row
          class="my-2"
        >
          <b-col
            span="12"
            class="primary d-flex justify-content-end"
          >
            <b-button
              variant="primary"
              :disabled="loading"
              @click="updateValue(product.categoryInformations)"
            >
              <span class="text-nowrap">Enregistrer</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-card>
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="3"
          md="3"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <v-select
            v-model="country"
            placeholder="Pays"
            label="Pays"
            :options="countryOptions"
            :clearable="false"
            class="invoice-filter-select mt-1"
          />
        </b-col>
      </b-row>
    </b-card>
    <div class="mt-1 mb-3">
      <b-row>
        <b-col
          cols="12"
        >
          <div class="px-2 py-1 navbar-light header-navbar rounded-lg shadow d-flex align-items-center justify-content-start">
            <feather-icon
              icon="SearchIcon"
              class="mr-50 text-primary"
              size="20"
            />
            <b-input-group class="input-group-merge shadow-none">
              <b-form-input
                placeholder="Rechercher un produit..."
                style="height: auto;"
                class="border-0 shadow-none"
                @input="filter"
              />
            </b-input-group>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-card title="Vous pouvez modifier les produits directement ici.">
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <b-row v-else class="match-height">
        <b-col
          v-for="(pdct, keyP) in products"
          :key="keyP"
          md="3"
          lg="3"
          class=""
        >
          <b-card
            class="ecommerce-card"
            no-body
          >
            <div
              v-if="pdct.images && pdct.images.length"
              class="item-img text-center"
            >
              <b-img
                :alt="`${pdct.title}`"
                fluid
                class="card-img-top"
                style="height: 180px; object-fit: cover"
                :src="pdct.images[0].src"
              />
            </div>

            <!-- pdct Details -->
            <b-card-body>
              <div class="item-wrapper">
                <div class=" d-flex justify-content-between">
                  <h5 class="item-price text-primary text-lighten-2">
                    {{ pdct.price }} €
                  </h5>
                  <h5 class="item-price text-primary text-secondary">
                    {{ pdct.id }}
                  </h5>
                </div>
              </div>
              <h4 class="item-name">
                {{ pdct.title || pdct.name }}
              </h4>
              <!-- <b-card-text class="item-description">
                {{ product.short_description }}
              </b-card-text> -->
            </b-card-body>

            <!-- Product Actions -->
            <div class="item-options text-center">
              <b-button
                variant="flat-primary"
                tag="a"
                class="btn-cart mb-1"
                @click="openModal(pdct)"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span>Modifier le produit</span>
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BModal,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BButton,
  BFormInput,
  BInputGroup,
  BSpinner,
  BFormFile,
} from 'bootstrap-vue'
import Media from '@components/cloudinary/Modal.vue'
import QuillEditorSnow from '@core/components/quill-editor/QuillEditorCustom.vue'
import { Quill, quillEditor } from 'vue-quill-editor'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Pinterest from '@components/pinterest/Modal.vue'
import vSelect from 'vue-select'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    vSelect,
    BCard,
    BModal,
    BRow,
    BCol,
    BImg,
    BButton,
    BCardBody,
    BSpinner,
    QuillEditorSnow,
    AppCollapse,
    AppCollapseItem,
    BFormInput,
    BInputGroup,
    Media,
    quillEditor,
    BFormFile,
    Pinterest,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
  },
  watch: {
    country: {
      deep: true,
      handler: 'getProducts',
    },
  },
  data() {
    return {
      baseProducts: [],
      pinterestProduct: false,
      products: [],
      product: {},
      showModal: false,
      file: null,
      loading: false,
      disabledImport: false,
      options: { mode: 'code' },
      categoryInformations: [],
      country: 'FR',
      countryOptions: ['ES', 'FR', 'DE', 'IT', 'PT', 'NL', 'EN', 'BB', 'DK'],
      snowOption: {
        theme: 'snow',
        modules: {
          toolbar: ['bold', 'italic', 'underline', 'link'],
          clipboard: {
            matchVisual: false,
          },
        },
      },
      whithoutOption: {
        theme: 'snow',
        modules: {
          toolbar: [],
        },
      },
    }
  },
  async mounted() {
    try {
      const Block = Quill.import('blots/block')
      Block.tagName = 'div'
      Quill.register(Block, true)
      await this.getProducts()
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    async getProducts() {
      try {
        this.loading = true
        const { products } = await this.$http.get('/admin/list-data-product-v2', { params: { filter: { country: this.country } } })
        this.products = products
        this.baseProducts = products
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    addBloc() {
      this.categoryInformations.lg.seo.blocs.push({ title: '', text: '' })
    },
    convertHtml(html) {
      // eslint-disable-next-line newline-per-chained-call
      return html?.replaceAll('<span style="color: rgb(0, 0, 0);">', '')?.replaceAll('</span>', '')?.replaceAll('style="color: rgb(0, 0, 0);"', '')
      // this.content = html
    },
    openModal(product) {
      this.product = product
      this.categoryInformations = product.categoryInformations
      if (!this.categoryInformations.lg?.seo?.blocs) {
        this.categoryInformations.lg.seo = {
          blocs: [{ title: '', text: '' }],
        }
      }
      if (!this.categoryInformations.lg?.descriptions) {
        this.categoryInformations.lg.descriptions = [{ title: '', description: '' }]
      }
      if (!this.categoryInformations.lg.pictosCategory) {
        this.categoryInformations.lg.pictosCategory = ['']
      }
      if (!this.categoryInformations.lg.knows) {
        this.categoryInformations.lg.knows = {}
      }
      if (!this.categoryInformations.lg.faqV2) {
        this.categoryInformations.lg.faqV2 = { title: '', values: [{ title: '', sections: [{ title: '', content: '' }] }] }
      }
      if (!this.categoryInformations.lg.materials) {
        this.categoryInformations.lg.materials = { values: [{ title: '', content: '', image: { src: '', alt: '' } }] }
      }
      if (!this.categoryInformations.lg.commitment) {
        this.categoryInformations.lg.commitment = {}
      }
      if (!this.categoryInformations.lg.informationsPointCategory) {
        this.categoryInformations.lg.informationsPointCategory = ['']
      }
      if (!this.categoryInformations.lg.videoGrid) {
        this.categoryInformations.lg.videoGrid = ''
      }
      if (!this.categoryInformations.lg.videoSchema) {
        this.categoryInformations.lg.videoSchema = ''
      }
      if (!this.categoryInformations.lg.snackContent) {
        this.categoryInformations.lg.snackContent = { description: '', reinsurance: '' }
      }
      if (!this.categoryInformations.lg.categoryPlace) {
        this.categoryInformations.lg.categoryPlace = 999
      }
      if (this.categoryInformations.lg.publications === null) {
        this.categoryInformations.lg.publications = { title: '', values: [{ title: '', src: '', description: '' }] }
      }
      if (!this.categoryInformations.lg.matter) {
        this.categoryInformations.lg.matter = {
          title: '',
          bloc1: {
            title: '',
            description: '',
            values: [{
              content: {
                content: '',
              },
            }],
          },
        }
      }
      this.showModal = true
    },
    async updateValue() {
      try {
        this.loading = true
        this.categoryInformations.lg.tabs[0].value = this.convertHtml(this.categoryInformations.lg.tabs[0].value)
        // this.categoryInformations.lg.tabs[1].value = this.convertHtml(this.categoryInformations.lg.tabs[1].value)
        // this.categoryInformations.lg.tabs[2].value = this.convertHtml(this.categoryInformations.lg.tabs[2].value)
        this.categoryInformations.lg.materials.values = this.categoryInformations.lg.materials.values.map(v => ({ ...v, content: this.convertHtml(v.content) }))
        this.categoryInformations.lg.reinsurance.subtitle = this.convertHtml(this.categoryInformations.lg.reinsurance.subtitle)
        this.categoryInformations.lg.reinsurance.values = this.categoryInformations.lg.reinsurance.values?.map(v => ({ ...v, description: this.convertHtml(v.description) }))
        this.categoryInformations.lg.history.description = this.convertHtml(this.categoryInformations.lg.history.description)
        this.categoryInformations.lg.certifications.values = this.categoryInformations.lg.certifications.values?.map(v => ({ ...v, text: this.convertHtml(v.text) }))
        this.categoryInformations.lg.matter.bloc1.description = this.convertHtml(this.categoryInformations.lg.matter.bloc1.description)
        this.categoryInformations.lg.matter.bloc1.values = this.categoryInformations.lg.matter.bloc1.values.map(v => ({ ...v, content: { content: this.convertHtml(v.content.content) } }))
        // this.categoryInformations.lg.publications.values = this.categoryInformations.lg.publications.values.map(v => ({ ...v, description: this.convertHtml(v.description) }))
        this.categoryInformations.lg.details.values = this.categoryInformations.lg.details.values.map(v => ({ ...v, content: { content: this.convertHtml(v.content.content) } }))
        this.categoryInformations.lg.faq.values = this.categoryInformations.lg.faq.values.map(v => ({ ...v, content: { content: this.convertHtml(v.content.content) } }))
        // this.categoryInformations.lg.faqV2.values = this.categoryInformations.lg.faqV2.values.map(v => ({ ...v, content: { content: this.convertHtml(v.content.content) } }))
        this.categoryInformations.lg.seo.blocs = this.categoryInformations.lg.seo.blocs?.map(v => ({ ...v, text: this.convertHtml(v.text) }))
        this.categoryInformations.lg.descriptions.blocs = this.categoryInformations.lg.descriptions?.map(v => ({ ...v, description: this.convertHtml(v.description) }))
        await this.$http.put(`admin/products/${this.product._id}/v2`, { categoryInformations: this.categoryInformations })
        await this.getProducts()
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Enregistrement réussi',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    deleteElementFaqV2(key) {
      console.log('fsqjkdfns', this.categoryInformations.lg.faqV2.values)
      this.categoryInformations.lg.faqV2.values.splice(key, 1)
      console.log('dfd', this.categoryInformations.lg.faqV2.values)
    },
    async upload(f) {
      if (!this.file) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Vous devez renseigner tous les champs.',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
      } else {
        console.log(f)
        setTimeout(() => {}, 2000)
        const form = new FormData()
        form.append('file', this.file, this.file.name)
        try {
          await this.$http.post(`/importAll/import-content/${this.product._id}`, form)
          this.file = null
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Votre contenu a bien été importé.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          await this.getProducts()
        } catch (err) {
          console.log(err)
        }
      }
    },
    async importWoo() {
      try {
        this.disabledImport = true
        await this.$http.get(`admin/products/${this.product._id}/import-woo/?country=${this.country}&id=${this.product.id}`)
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Import réussi',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      } catch (err) {
        console.log(err)
      } finally {
        this.disabledImport = false
      }
    },
    errorJson(err) {
      console.log(err)
    },
    filter(e) {
      this.filterQuery = e
      if (!e || e === '') {
        this.products = this.baseProducts
      } else {
        const value = e
        this.products = this.baseProducts.filter(product => product.name.toLowerCase().includes(value.toLowerCase()))
      }
    },
  },
}
</script>

<style>
 .jsoneditor-poweredBy {
   display: none;
 }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
